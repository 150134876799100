<template>
  <div>
    <PaxWidget
      :paxObject="params.paxObject"
      :kalkPax="params.kalkPax"
      :erwartetePax="params.erwartetePax"
      :durchfuehrbareMinPax="params.durchfuehrbareMinPax"
      :id="params.id"
    ></PaxWidget>
  </div>
</template>

<script>
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';

export default {
  components: {
    PaxWidget,
  },
};
</script>
<style></style>
