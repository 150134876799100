<template>
  <div>
    <div v-if="params.value === null">-</div>
    <i v-else :class="releaseprozessIconClass(params)"></i>
    <i
      v-if="passdatenStar === false"
      class="fas fa-asterisk text-danger icon-sm position-absolute translate-middle"
      title="Passdaten nicht vollständig"
    ></i>
  </div>
</template>

<script>
import { releaseprozessIconClass } from '@/core/common/helpers/utils.js';

export default {
  computed: {
    passdatenStar() {
      if (this.params.extra === 'passdatenStar') {
        return this.params.data.vorgangReleaseprozess.configurationFulfilledForAllTeilnehmer;
      } else return null;
    },
  },
  methods: {
    releaseprozessIconClass,
  },
};
</script>
