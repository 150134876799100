<template>
  <div class="d-flex my-4">
    <div>
      <span v-if="rowCount && odataCount">Gezeigt werden {{ rowCount }} von {{ odataCount }} Zeilen</span
      ><span v-if="markedCount && markedCount > 0"> ({{ markedCount }} ausgewählt)</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rowCount: null,
      odataCount: null,
      markedCount: null,
    };
  },
  methods: {
    setRowCount(rowCount) {
      this.rowCount = rowCount;
    },
    setOdataCount(odataCount) {
      this.odataCount = odataCount;
    },
    setMarkedCount(markedCount) {
      this.markedCount = markedCount;
    },
  },
};
</script>
