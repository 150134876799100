<template>
  <div>
    <AufgabenWidget
      :filter="params.filter"
      :aufgaben="params.data.aufgaben"
      :ansicht="params.ansicht"
      :onPlusSignClicked="params.onPlusSignClicked"
      :data="params.data"
    ></AufgabenWidget>
  </div>
</template>

<script>
import AufgabenWidget from '@/components/produkte/reisetermine/aufgaben-widget.vue';

export default {
  components: { AufgabenWidget },
};
</script>
<style></style>
